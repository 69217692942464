@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .navbar__big {
    @apply px-3 py-2 font-semibold text-[#2A3158] text-xl ;
  }
  .navbar__small {
    @apply text-[#2A3158] block px-3 py-2 text-base font-semibold ;
  }
}